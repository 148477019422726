import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

function TwoDCanvas({ color, text }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current,{
        backgroundColor: "lightgray"
    });

    const rect = new fabric.Rect({
      left: 100,
      top: 100,
      width: 50,
      height: 50,
      fill: color,
      objectCaching: false,
    });

    const textObject = new fabric.Textbox(text, {
      left: 100,
      top: 200,
      width: 100,
      fontSize: 16,
      fontFamily: 'Arial',
      fill: color,
      objectCaching: false,
    });

    canvas.add(rect, textObject);
    canvas.renderAll();

    return () => {
      canvas.dispose();
    };
  }, [color, text]);

  return <canvas ref={canvasRef} width={300} height={300}></canvas>;
}

export default TwoDCanvas;
