import React, { useRef , Suspense} from 'react';
import { useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { Decal, Environment, OrbitControls, PresentationControls, Stage, Float, ContactShadows } from '@react-three/drei';
// import { useLoader } from '@react-three/fiber'
import { Model } from './components/Cup';
import { Model1 } from './components/coffeemug';
import { Mug } from './Mug';

function CupModel(props) {
  
  

  return (
    // <PresentationControls speed={1.5} global zoom={0.7} polar={[-0.1, Math.PI/2]}> 
    //   <Stage environment={"city"} intensity={0.6} contactShadow = {false} >
    //   <Suspense fallback={null}>
    //  <Model/>
    //       {/* <Environment preset="sunset" background /> */}
    //     </Suspense>
    //   </Stage>
    //   {/* <mesh scale={4} position={[3, -1.161, -1.5]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
    //     <ringGeometry args={[0.9, 1, 4, 1]} />
    //     <meshStandardMaterial color="white" roughness={0.75} />
    //   </mesh>
    //   <mesh scale={4} position={[-3, -1.161, -1]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
    //     <ringGeometry args={[0.9, 1, 3, 1]} />
    //     <meshStandardMaterial color="white" roughness={0.75} />
    //   </mesh> */}
    // </PresentationControls>
    <>
    <OrbitControls/>
    {/* <Float> */}
    <Mug/>
      {/* </Float> */}
   
    <ContactShadows position-y={-0.5} opacity={0.4} blur={2} />
      {/* <Environment preset="sunset" background blur={4} /> */}
    {/* <mesh>
      <Decal
      debug
      position={}
      rotation={}
      scale={}>
        

      </Decal>
    </mesh> */}
    </>
    
  );
}

export default CupModel;
