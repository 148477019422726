import React, { useRef, useState } from 'react'
import { Decal, useGLTF, useTexture } from '@react-three/drei'
import { useControls } from 'leva'
import { degToRad } from 'three/src/math/MathUtils.js';
import * as THREE from 'three';



export function Mug(props) {
  const { nodes, materials } = useGLTF('/models/mug3.glb')
//   const texture = useTexture('/texture/sadgirl.jpg') 

  useControls({
    angle: {
      min: degToRad(20),
      max: degToRad(300),
      value: Math.PI / 4,
      step: 0.01,
      onChange: (value) => {
        const x = Math.cos(value)-3.5;
        const z = Math.sin(value);
        const rot = Math.atan2(x, z);
        setRotation(() => [0, rot, 0]);
        setPos((pos) => [x, pos[1], z]);
      },
    },
    posY: {
      min: 3,
      max: 5,
      value: 4,
      step: 0.01,
      onChange: (value) => {
        setPos((pos) => [pos[0], value, pos[2]]);
      },
    },
    scale: {
      min: 4,
      max: 8,
      value: 6,
      step: 0.01,
      onChange: (value) => {
        setScale(() => [value, value, 1.5]);
      },
    },
  });

  const [pos, setPos] = useState([0,6,4.5]);
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [scale, setScale] = useState([2,2,2]);

  const texture = new THREE.TextureLoader().load( "/texture/sadgirl.jpg" );
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.rotation = Math.PI;
  texture.repeat.set( 1, 1 );

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={1.622}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.cup_lp_cup_0_1.geometry}
            material={materials.material}
          />
          <mesh
           
            geometry={nodes.cup_lp_cup_0_2.geometry}
          >
             <meshBasicMaterial transparent opacity={0}/>
      {/* <Decal
      debug
      position={pos} // Position of the decal
      rotation={rotation} // Rotation of the decal (can be a vector or a degree in radians)
      scale={scale}> */}
        <meshBasicMaterial map={texture} polygonOffset polygonOffsetFactor={-1}/>
        

      {/* </Decal> */}

          </mesh>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/mug3.glb')
