import React, { useState } from 'react';
import './App.css';
import ColorPicker from './components/ColorPicker';
import TextEditor from './components/TextEditor';
import ThreeCanvas from './components/ThreeCanvas';
import TwoDCanvas from './components/TwoDCanvas';
import { Canvas } from '@react-three/fiber';
import CupModel from './CupModel';
import FloatingImage from './components/Canvas';

function App() {
  const [selectedColor, setSelectedColor] = useState('black');
  const [text, setText] = useState('');

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleTextChange = (newText) => {
    setText(newText);
  };

  return (
    <div >
      {/* <div className="sidebar">
        <h2>Side Panel</h2>
        <ColorPicker onColorChange={handleColorChange} />
        <TextEditor onTextChange={handleTextChange} />
      </div>
      <div className="canvas">
        <h2>Canvas</h2>
        <TwoDCanvas color={selectedColor} text={text} />
      </div> */}
      {/* <div >
        <h2>3D Model</h2>
        <ThreeCanvas color={selectedColor} text={text} />
      </div> */}


{/* <div >
      <Canvas  gl={{ antialias: false }}
      camera={{ position: [0, 0, 15], fov: 25 }} 
      >
      {/* <color attach="background" args={['#15151a']} /> */}

        {/* <CupModel/>
      </Canvas> */}
      {/* </div> */} 
<FloatingImage/>
    </div>
    
  );
}

export default App;
