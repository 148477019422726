import React, { useRef, useState } from 'react'
import { Decal, useGLTF, useTexture } from '@react-three/drei'
import {useControls} from "leva";
import { degToRad } from 'three/src/math/MathUtils.js';

export function Model1(props) {
  const { nodes, materials } = useGLTF('/models/mug1.glb')
  const texture = useTexture('/texture/sadgirl.jpg') 
  const decal = useRef();

  useControls({
    angle: {
      min: degToRad(60),
      max: degToRad(300),
      value: Math.PI / 4,
      step: 0.01,
      onChange: (value) => {
        const x = Math.cos(value);
        const z = Math.sin(value);
        const rot = Math.atan2(x, z);
        setRotation(() => [0, rot, 0]);
        setPos((pos) => [x, pos[1], z]);
      },
    },
    posY: {
      min: 0,
      max: 3,
      value: 1.8,
      step: 0.01,
      onChange: (value) => {
        setPos((pos) => [pos[0], value, pos[2]]);
      },
    },
    scale: {
      min: 0.5,
      max: 3,
      value: 1.5,
      step: 0.01,
      onChange: (value) => {
        setScale(() => [value, value, 1.5]);
      },
    },
  });

  const [pos, setPos] = useState([0, 1.8, 1]);
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [scale, setScale] = useState([1.5, 1.5, 1.5]);
  

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cup_lp_cup_0_1.geometry}
        material={materials.material}
      />
      <mesh
        geometry={nodes.cup_lp_cup_0_2.geometry} 
     >
      <meshBasicMaterial transparent opacity={0}/>
      <Decal
          // debug // Makes "bounding box" of the decal visible
          position={pos} // Position of the decal
          rotation={rotation} // Rotation of the decal (can be a vector or a degree in radians)
          scale={scale} // Scale of the decal
        >
<meshStandardMaterial
            map={texture}
            toneMapped={false}
            transparent
            polygonOffset
            polygonOffsetFactor={-1} // The mesh should take precedence over the original
          />        

      </Decal>
      
     </mesh>
    </group>
  )
}

useGLTF.preload('/models/mug1.glb')