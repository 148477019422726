import React, { useRef, useEffect, useState } from 'react';

const FloatingImage = () => {
  const canvasRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image
  const [showPreview, setShowPreview] = useState(false); // State to toggle preview
  const [downloadOriginal, setDownloadOriginal] = useState(false); // State to toggle preview


   
  useEffect(() => {

    if (!selectedImage) return; // Early return if no image is selected


    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width
    const height = canvas.height
    // Set up the canvas dimensions
    // canvas.width = canvasRef.current.width;
    // canvas.height = canvasRef.current.height;

    const canvas2 = document.createElement('canvas');
    canvas2.width = width; canvas2.height = height;
    const ctx2 = canvas2.getContext('2d');
     // Clear the canvas
    //  ctx.clearRect(0, 0, width, height);

    // Load the image
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Important for cross-origin images

    img.src = selectedImage;
    img.onload = () => {

      if (downloadOriginal) {
        // Download the original image without applying any shadow or pixel change
        const link = document.createElement('a');
        link.download = 'original_image.png';
        link.href = canvas.toDataURL();
        link.click();
        setDownloadOriginal(false); // Reset downloadOriginal to false

      } else{
 // ctx.save();
 var hRatio = canvas.width / img.width    ;
 var vRatio = canvas.height / img.height  ;
 var ratio  = Math.min ( hRatio, vRatio );

 var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  


 // Apply shadow
 ctx.shadowColor = 'white'; // Green for demo purposes
 ctx.shadowBlur = 10;
 ctx.shadowOffsetX = 0;
 ctx.shadowOffsetY = 0;
ctx.fillStyle="rgb(0,0,255)"

//   // Draw the image with shadow
  ctx.drawImage(img, 0,0, img.width, img.height,
   centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);  


    // Get contents of blurry bordered image
    const imageData = ctx.getImageData(0, 0, ctx.canvas.width - 1, ctx.canvas.height - 1);
    const opaqueAlpha = 255;

    // Turn all non-transparent pixels to full opacity
    for (let i = imageData.data.length; i > 0; i -= 4) {
      if (imageData.data[i + 3] > 0) {
        imageData.data[i + 3] = opaqueAlpha;
      }
    }

    // Write transformed opaque pixels back to image
    ctx.putImageData(imageData, 0, 0);


    // Reset shadow properties for the grey drop shadow
 ctx.shadowColor = 'red'; // Grey for the drop shadow
 ctx.shadowBlur =10;
 ctx.shadowOffsetX = 0;
 ctx.shadowOffsetY = 0;

 // Draw the image again with the grey drop shadow
 ctx.drawImage(canvas, 0, 0,);
      }

     
    };
  }, [selectedImage, downloadOriginal]);

  
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext('2d');

  //   const img = new Image();
  //   img.onload = draw;
  //   img.src = "/assets/brain1.png";

  //   function draw() {
  //     const dArr = [-1, -1, 0, -1, 1, -1, -1, 0, 1, 0, -1, 1, 0, 1, 1, 1]; // offset array
  //   //   const dArr = [-2, -2, 0, -2, 2, -5, -4, 0, 1, 0, -1, 1, 0, 1, 1, 1]; // offset array

  //     const s = 2; // thickness scale
  //     let i = 0; // iterator
  //     const x = 5; // final position
  //     const y = 5;

  //      // Calculate maximum offsets
  // const maxOffsetX = Math.min(canvas.width - img.width, canvas.width / 2);
  // const maxOffsetY = Math.min(canvas.height - img.height, canvas.height / 2);

  // // Adjust drawing loop to use maximum offsets
  // for (; i < dArr.length; i += 2) {
  //   // Ensure the offsets stay within the canvas bounds
  //   const adjustedX = Math.max(Math.min(x + dArr[i] * s, maxOffsetX), 0);
  //   const adjustedY = Math.max(Math.min(y + dArr[i + 1] * s, maxOffsetY), 0);
  //   ctx.drawImage(img, adjustedX, adjustedY);
  // }

  //     // Fill with color
  //     ctx.globalCompositeOperation = "source-in";
  //     ctx.fillStyle = "red";
  //     ctx.fillRect(0, 0, canvas.width, canvas.height);

  //     // Draw original image in normal mode
  //     ctx.globalCompositeOperation = "source-over";
  //     ctx.drawImage(img, x, y);
  //   }
  // }, []);

  



// useEffect(() => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
  
//     const img = new Image();
//     img.onload = draw;
//     img.src = "/assets/brain1.png";
  
//     function draw() {
//       const dArr = [-2, -2, 0, -2, 2, -5, -4, 0, 1, 0, -1, 1, 0, 1, 1, 1]; // offset array
//       const s = 2; // thickness scale
//       let i = 0; // iterator
//     //   const x = 5; // final position
//     //   const y = 5;
  
//       // Draw images at offsets from the array scaled by s
//     //   for (; i < dArr.length; i += 2) {
//     //     ctx.drawImage(img, x + dArr[i] * s, y + dArr[i + 1] * s);
//     //   }
  

//     context.shadowColor = "red";
// context.shadowBlur = 0;

// // X offset loop
// for(var x = -2; x <= 2; x++){
//     // Y offset loop
//     for(var y = -2; y <= 2; y++){
//         // Set shadow offset
//         context.shadowOffsetX = x;
//         context.shadowOffsetY = y;

//         // Draw image with shadow
//         context.drawImage(img, x, y,);
//     }
// }
//       // Apply shadow to the red border
//     //   ctx.shadowColor = "black";
//     //   ctx.shadowBlur = 5;
//     //   ctx.shadowOffsetX =15;
//     //   ctx.shadowOffsetY = 8;
  
//     //   // Fill with color
//     //   ctx.globalCompositeOperation = "source-in";
//     //   ctx.fillStyle = "red";
//     //   ctx.fillRect(0, 0, canvas.width, canvas.height);
  
//     //   // Reset shadow properties
//     //   ctx.shadowColor = "black";
//     // //   ctx.shadowBlur = 0;
//     // //   ctx.shadowOffsetX = -10;
//     // //   ctx.shadowOffsetY = 10;
  
//     //   // Draw original image in normal mode
//     //   ctx.globalCompositeOperation = "source-over";
//     //   ctx.drawImage(img, x, y);
//     }
//   }, []);
  

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (event) => {
    setSelectedImage(event.target.result); // Update the state with the image URL
  };
  reader.readAsDataURL(file);
};

const handlePreview = () => {
  setShowPreview(!showPreview);
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const width = canvas.width;
  const height = canvas.height;

  // Invert white pixels to transparency
  const imageData = ctx.getImageData(0, 0, width, height);
  const transparentAlpha = 0; // Change this line to set alpha to zero
  var pixels = imageData.data;
  var numPixels = pixels.length;
  // Turn all non-transparent pixels to zero opacity
  for (var i = 0; i < numPixels; i += 4) {
    if (pixels[i] === 255 && pixels[i + 1] === 255 && pixels[i + 2] === 255) {
      pixels[i + 3] = transparentAlpha;
    }
  }
  // Write transformed opaque pixels back to image
  ctx.putImageData(imageData, 0, 0);
};

const handleDownload = () => {
  const canvas = canvasRef.current;
  const link = document.createElement('a');
  link.download = 'image.png';
  link.href = canvas.toDataURL();
  link.click();
};

const handleDownloadOriginal = () => {
  const link = document.createElement('a');
  link.download = 'original_image.png';
  link.href = selectedImage;
  link.click();
};

const handleDownloadcutimage = () => {
  const canvas = canvasRef.current;
  const link = document.createElement('a');
  link.download = 'cut_image.png';
  link.href = canvas.toDataURL();
  link.click();
};



const handleUploadImage = () => {
  const canvas = document.getElementById('canvas');
  const ctx = canvas.getContext('2d');
  
  // Load the image
  const img = new Image();
  img.onload = () => {
    ctx.drawImage(img, 0, 0);
  
    // Make the image fully opaque
    img.style.opacity = '1';
  
    // Get the image data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const opaqueAlpha = 255;

    // Change all pixels to opaque
    for (let i = data; i > 0; i -= 4) {
      if (data[i + 3] < 255) {
        data[i + 3] = opaqueAlpha;
      }
    }
  
    // Put the modified image data back onto the canvas
    ctx.putImageData(imageData, 0, 0);
  };
  img.src = selectedImage;

};

  return(
    // <div className="fabric-canvas">
    // <input type="file" accept="image/*" onChange={handleFileChange} />
    // <button onClick={handlePreview}>{showPreview ? 'Hide Preview' : 'Show Preview'}</button>
    // <button onClick={handleDownload}>Download as PNG</button>
    // <canvas ref={canvasRef} width={700} height={700} />;
    // </div>
    <div className="container">
  <div className="fabric-canvas">
  <input type="file" accept="image/*" onChange={handleFileChange} />
  <button onClick={handlePreview}>{showPreview ? 'Hide Preview' : 'Show Preview'}</button>

  <canvas id='canvas' ref={canvasRef} width={700} height={600} />
  </div>
  <div className="image-generation">
  <button onClick={handleDownloadOriginal}>Color Data</button>
  <button onClick={handleDownload} id="download-with-border">Cut Data Image</button>
  <button onClick={handleDownloadcutimage} id="download-with-shadow">Shadow Image</button>
  {/* <button onClick={handleUploadImage}>Upload Image</button> */}

  </div>
</div>
  )
};

export default FloatingImage;
